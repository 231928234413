/* Container holding the Teams component */
.teams-container {
    margin: 20px;
  }
  
  /* Individual team card */
  .team-card {
    min-height: 540px;
    /* background-color: #f7f7f7; */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(234, 233, 233, 0.1);
    overflow: hidden;
    padding: 2rem;
    transition: transform 0.3s ease;
  
    &:hover {
      transform: scale(1.05);
    }
  
    /* Hexagonal profile picture container */
    .profile-pic-hexagon {
        width: 200px;
        height: 200px;
        overflow: hidden;
        margin: 0 auto;
        border: 1px solid;
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        border-radius: 10px;
        
      /* margin-top: -50px; */
  
      /* Profile picture inside the hexagon */
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  
    /* Details section of the team card */
    .team-details {
      padding: 20px;
      text-align: center;
  
      /* Team member name */
      h3 {
        margin-bottom: 5px;
      }
  
      /* Designation text */
      span {
        color: #888;
      }
  
      /* Bio text */
      p {
        font-size: 14px;
        margin-top: 10px;
      }
  
      /* Social media links */
      .team-social {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
  
        /* Individual social media link */
        div {
          margin-right: 10px;
  
          /* Social media link styling */
          a {
            text-decoration: none;
            color: #3498db;
            font-weight: bold;
            transition: color 0.3s ease;
  
            /* Hover effect on social media links */
            &:hover {
              color: #2980b9;
            }
          }
        }
      }
    }
  }
  
  /* Swiper container styling */
  .mySwiper {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  

  /* .hide{
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
  } */


  @media screen and (max-width:768px) {
    
  .team-card{
    padding: 0.2rem;
  }
  }