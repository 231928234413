/* PdfView.css */

.pdf-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1099;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pdf-modal-content {
/* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 90px;
    right: 10px;
    cursor: pointer;

    background: red;
    border: none;
    padding: 0px 20px;
    font-size: 18px;
    color: #333;
  }
  
  .pdf-modal-content iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
  
  iframe::-webkit-scrollbar{
    display: none;
  }