.wrapperAlert {
    width: 500px;
    height: 400px;
    overflow: hidden;
    border-radius: 12px;
    border: thin solid #ddd;           
  }
  .actiavte-page{
    height: 100vh;
  display: flex;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: 'Khand', sans-serif; 
  }

  .topHalf {
    width: 100%;
    color: white;
    overflow: hidden;
    min-height: 250px;
    position: relative;
    padding: 40px 0;
    background: rgb(0,0,0);
    background: -webkit-linear-gradient(45deg, #019871, #a0ebcf);
  }
  
  .topHalf p {
    margin-bottom: 30px;
  }
  svg {
    fill: white;
  }
  .topHalf h1 {
    font-size: 2.25rem;
    display: block;
    font-weight: 500;
    letter-spacing: 0.15rem;
    text-shadow: 0 2px rgba(128, 128, 128, 0.6);
  }
        
  
  
.bottomHalf {
    align-items: center;
    padding: 35px;
  }
  .bottomHalf p {
    font-weight: 500;
    font-size: 1.05rem;
    margin-bottom: 20px;
  }
  
  .bottomHalf button {
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 12px;            
    padding: 10px 18px;            
    background-color: #019871;
    text-shadow: 0 1px rgba(128, 128, 128, 0.75);
  }
  .bottomHalf button:hover {
    background-color: #85ddbf;
  }