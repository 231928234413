/* ReviewModal.css */

.review-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background-color: rgba(8, 8, 8, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .review-modal {
    background-color: transparent;
    border-radius: 8px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  .review-modal h3{
  }
  .modal-content {
    padding: 20px;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .modal-content img {
    max-width: 100%;
    cursor: pointer;
    border-radius: 12px;
  }
  