/* Support container styles */
.support-container {
    display: flex;
    height: 100vh;
  }
  
  .support-image {
    flex: 1;
    overflow: hidden;
  }
  
  .support-image img {
    width: 100%;
    height: 100%;

  }
  
  .support-content {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .support-content > h1 {
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .support-content > p {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .support-content > strong {
    font-weight: bold;
  }
 
  .contact-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .contact-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
.conatct {
    --color: #560bad;
    font-family: inherit;
    display: inline-block;
    width: 8em;
    height: 2.6em;
    line-height: 2.5em;
    margin: 20px;
    position: relative;
    overflow: hidden;
    border: 2px solid var(--color);
    transition: color .5s;
    z-index: 1;
    font-size: 17px;
    border-radius: 6px;
    font-weight: 500;
    color: var(--color);
   }
   
 .conatct:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--color);
    height: 150px;
    width: 200px;
    border-radius: 50%;
   }
   
 .conatct:hover {
    color: #fff;
   }
   
 .conatct:before {
    top: 100%;
    left: 100%;
    transition: all .7s;
   }
   
 .conatct:hover:before {
    top: -30px;
    left: -30px;
   }
   
 .conatct:active:before {
    background: #3a0ca3;
    transition: background 0s;
   }