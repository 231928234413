/* Faq.css */

.faq-container {
    display: flex;
    max-width: 1500px;
    align-items: center;
    justify-content: center;
    padding: 40px;
    margin: 50px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .image-container {
    flex: 1;
    overflow: hidden;
  }
  
  .image-container img {
    width: 50%;
    height: 100%;
    object-fit: cover;
  }
  
  .faq-content {
    flex: 1;
    padding: 20px;
  }
  
  .faq-content h2 {
    color: #ff6016;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
  }
  
  .faq-item {
    margin-bottom: 15px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s ease;
  }
  
  .faq-item:hover {
    background-color: #f9f9f9;
  }
  
  .question {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  
  .answer {
    padding: 10px;
    display: none;
  }
  
  .faq-item.open .answer {
    display: block;
  }
  