.gallery-container {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    max-width: 1200px;
    padding: 3rem;
    margin: 0 auto;
    gap: 2rem;
  }
  .gallery-wall h3{
    text-align: center;
    font-size: 3rem;
    color: #fff;
    z-index: 9;
    
    position: relative;
  }
  .gallery-wall {
    background-attachment: scroll;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
 background-image: url(./wall.webp);
 /* background-color: rgba(255, 255, 255, 0.8); */
  }
  .gallery-wall::before{
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
     background-color: rgba(27, 25, 25, 0.6);

  }
.paper {
    --paper-dark: #e5c93d;
    --paper-color: #ffed87;
  
    position: relative;
    display: flex;
    justify-content: center;
    min-width: 125px;
    min-height: 175px;
    background: linear-gradient(
      135deg,
      var(--paper-dark),
      30%,
      var(--paper-color)
    );
    cursor: grab;
    box-shadow: 3px 3px 2px var(--paper-shadow);
    transform: rotate(10deg);
    transform-origin: top left;
  }
  .paper img{
    width: 100%;
  }
  
  .paper p {
    margin: auto;
  }
  
  .pin {
    --pin-color: #d02627;
    --pin-dark: #9e0608;
    --pin-light: #fc7e7d;
  
    position: absolute;
    left: 220px;
    width: 60px;
    height: 50px;
  }
  
  .shadow {
    position: absolute;
    top: 18px;
    left: -8px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: radial-gradient(var(--paper-shadow), 20%, rgba(201, 191, 141, 0));
  }
  
  .metal {
    position: absolute;
    width: 5px;
    height: 20px;
    background: linear-gradient(to right, #808080, 40%, #eae8e8, 50%, #808080);
    border-radius: 0 0 30% 30%;
    transform: rotate(50deg);
    transform-origin: bottom left;
    top: 15px;
    border-bottom: 1px solid #808080;
  }
  
  .bottom-circle {
    position: absolute;
    right: 15px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: var(--pin-color);
    background: radial-gradient(
      circle at bottom right,
      var(--pin-light),
      25%,
      var(--pin-dark),
      90%,
      var(--pin-color)
    );
  }
  
  /* Barrel */
  .bottom-circle::before {
    content: "";
    position: absolute;
    top: 0;
    left: -2px;
    width: 20px;
    height: 30px;
    transform: rotate(55deg);
    transform-origin: 100% 100%;
    border-radius: 0 0 40% 40%;
    background: linear-gradient(
      to right,
      var(--pin-dark),
      30%,
      var(--pin-color),
      90%,
      var(--pin-light)
    );
  }
  
  /* Top circle */
  .bottom-circle::after {
    content: "";
    position: absolute;
    right: -10px;
    top: -5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: radial-gradient(
      circle at right,
      var(--pin-light),
      30%,
      var(--pin-color),
      var(--pin-dark) 80%
    );
  }


  @media screen and (max-width:768px) {
    .gallery-container {
      grid-template-columns: repeat(2,1fr);
   
    }
    .pin {
      --pin-color: #d02627;
      --pin-dark: #9e0608;
      --pin-light: #fc7e7d;
      position: absolute;
      left: 76px;
      width: 60px;
      height: 50px;
  }
  }