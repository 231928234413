.change-password-container {
    text-align: center;
    margin: 20px;
  }
  
  .change-password-heading {
    color: #007bff;
  }
  
  .password-form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
  }
  
  .form-label {
    margin-bottom: 10px;
  }
  
  .form-input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-button:hover {
    background-color: #0056b3;
  }
  