.contact-form {
    max-width: 800px;
    margin: auto;
    padding: 2rem;
    background-color: var(--color-white);
  }
  
  .contact-input {
    margin-bottom: 15px;
  }
  
  .contact-input label {
    display: block;
    margin-bottom: 5px;
  }
  
  .contact-input input,
  .contact-input textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-btn {
    background-color: #4caf50;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Add more styles as needed */
  