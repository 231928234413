.product-info {
    /* display: flex; */
    border: 1px solid #ccc;
    padding: 2rem;
    /* align-items: center; */
    height: 100vh;
    justify-content: center;
    /* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
    
    margin: 10px;
    max-width: 600px; /* Adjust the maximum width as needed */
  }
  .demo_payments{
    margin: 0 auto;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1800px;
  }
  
  .Product-img img {
    width: 100%;
    height: auto;
  }
  
  .Product-into-text {
    display: flex;
    /* align-items: center; */
    justify-content: baseline;
    flex-direction: column;
    text-align: left;
    margin-left: 10px;
  }
  
  .normal-price {
    color: #333;
  }
  
  .free-price {
    color: green;
  }
  