/* Add these styles to your CSS file */

.paid_conatiner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: var( --color-light-blue); /* Change the background color as needed */
  }
  
  .paid_values{
    text-align: center;
    max-width: 1200px;
    /* border: 2px solid black; */
    margin: 0 auto;
  }
  .paid-object-img{
    width: 300px;
    padding: 25px;
    margin: 0 auto;

  }
  .paid-object-img img{
    width: 100%;
  }

  .social-icons{
    max-width: 100%;
    margin-top: 25px;
  }
  .social-icons ul{
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: center;
  }
  .social-icons ul li{
    max-width: 70px;
  }
  .social-icons ul li a img{ 
    max-width: 100%;
  }
  .thanks-msg{
    font-size: 3rem;
  }
  .thanks-msg img{
    max-width: 100px;
  }
  i{
    color: #f71414;
  }
  .contact-cta{
    padding: 14px;
  }
  .conatct-cta-main{
    padding: 0.2rem 2rem;
    color: #f0f0f0;
    text-decoration: none;
    font-weight: 700;
    background-color: rgb(255, 51, 0);
  }