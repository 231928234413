.book-btn-container {
  text-align: center;
  margin-top: 20px;
}

.main-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.main-btn:hover {
  background-color: #2980b9;
}

.second-button-container {
  margin-top: 20px;
}

.countdown-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.drive-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #2ecc71;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.drive-btn:hover {
  background-color: #27ae60;
}
