/* Add these styles to your existing CSS file */
.review-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .review-modal h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .review-modal label {
    display: block;
    margin-bottom: 5px;
  }
  
  .review-modal input,
  .review-modal textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
  }
  .review-modal textarea:focus {
    outline: greenyellow;
    outline-offset: 2px solid;
  }


  .review-modal button {
    background-color: #674299;
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;
  }
  .reviews-container{
    display: grid;
    margin: 0 auto;
    max-width: 1200px;
    gap: 2rem;
    grid-template-columns: repeat(4,1fr);
  }

.no-rview-conatiner{
  width: 100%;
  margin-bottom: 25px;
  text-align: center;
}
.close{
  margin-left: 12px;
}
.no-rview-conatiner img{
  width: 10%;
  height: auto;
  object-fit: cover;
}
.reviewtext{
  padding: 10px;
  color: #674299;
  font-size: 2rem;
  text-transform: capitalize;
}


  .btnreview{
    background-color: #674299;
    color: #fff;
    margin-left: 20px;
    padding: 10px;
    border: none;
    cursor: pointer;
  }